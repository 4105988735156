import clsx from 'clsx'

export default function StainOne({ className }: { className?: string }) {
  return (
    <svg
      width="648"
      height="715"
      viewBox="0 0 648 715"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M312.283 613.638C286.686 536.773 242.612 519.939 171.228 539.871L171.228 539.704C149.159 545.876 124.996 546.978 100.827 541.514C30.1375 525.37 -12.9816 459.742 4.67341 394.973C17.4393 348.08 58.5601 314.626 107.152 305.688L106.971 305.688C236.49 281.963 295.785 155.81 280.551 86.084C278.452 76.1111 278.443 65.4714 281.34 54.9121C291.752 16.8321 333.881 -6.39769 375.496 3.12342C410.568 11.0712 433.941 39.7273 434.333 71.2306C434.718 94.8373 439.546 110.128 503.788 127.857C606.374 156.108 667.691 253.39 641.525 349.504C636.274 368.71 627.933 386.423 617.046 402.393C580.396 457.288 582.407 470.419 603.796 516.367L603.615 516.367C616.449 543.869 620.111 575.203 611.601 606.465C590.958 682.874 506.609 729.251 423.289 710.209C368.318 697.708 327.677 659.674 312.282 613.389L312.283 613.638Z"
        fill="#EF5241"
      />
    </svg>
  )
}
