import clsx from 'clsx'

export default function Cross({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="137"
      height="137"
      viewBox="0 0 137 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path id="Vector" d="M62.4834 137V74.8063H0V62.4834H62.4834V0H74.8063V62.4834H137V74.8063H74.8063V137H62.4834Z" fill="#EF5241" />
    </svg>
  )
}
