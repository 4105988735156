import { useLosseLanguage, type LoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const [, locale] = useLosseLanguage()

  return (
    <nav data-component="Header" className="pt-5 lg:pt-8">
      <div className="container h-full">
        <div className="rounded-full bg-white flex items-center justify-between overflow-hidden px-8 sm:pl-10 lg:pl-16 sm:pr-10 lg:pr-20 py-3 lg:py-6 sm:py-4 box--shadow">
          <LosseLink to={locale === 'nl_NL' ? '/' : '/en/'} aria-label="Home">
            <img
              src="/static/logo.png"
              width="157"
              height="56"
              alt="Concept Online logo"
              className="w-[125px] h-[45px] lg:w-[157px] lg:h-[56px] object-contain"
            />
          </LosseLink>

          <HeaderDesktop />
          <HeaderMobile />
        </div>
      </div>
    </nav>
  )
}
