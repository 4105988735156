import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import { Autoplay, EffectFade } from 'swiper/modules'
import StainOne from '~/components/shapes/StainOne'
import Links from '~/components/elements/Links'
import DotBlock from '~/components/shapes/DotBlock'
import clsx from 'clsx'

export default function BannerBrand({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerBrand">
      <div className="container section">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-20 sm:gap-16 lg:gap-20 lg:pl-20">
          <div className="flex items-center order-3 sm:order-1">
            <div className="w-full">
              <Tag label={fields?.tag} className="max-sm:hidden" />
              <h1 className="title element--space">{fields?.title}</h1>
              <Content className="content element--space lg:pr-6 xl:pr-20">{fields?.description}</Content>
              <Links fields={fields} />
            </div>
          </div>
          <div className="order-2">
            <Tag label={fields?.tag} className="sm:hidden" />
            <div className="relative max-sm:w-[60%] max-sm:mx-auto">
              <Slider
                slidesPerView={1}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false
                }}
                loop={true}
                modules={[Autoplay, EffectFade]}
                effect="fade"
              >
                {fields?.images?.map((image, index) => (
                  <Slide key={index}>
                    <LossePlaatjie
                      loading="eager"
                      className="w-full sm:w-[95%] aspect-square object-cover rounded-full overflow-hidden relative z-10"
                      src={image}
                      maxwidth={1000}
                    />
                  </Slide>
                ))}
              </Slider>
              <StainOne className="absolute w-[115%] h-[115%] -right-[65px] -top-12 z-0" />
              <DotBlock className="absolute -bottom-5 right-0 sm:right-16 z-20 max-sm:w-[100px] max-sm:h-[100px] max-lg:w-[38px] max-lg:h-[150px]" />
              <div className="absolute bg-co-wild-sand h-[100px] w-[100px] sm:h-[150px] sm:w-[150px] lg:w-[210px] lg:h-[210px] -left-10 -bottom-10 flex--center z-20 rounded-full overflow-hidden">
                <LossePlaatjie
                  src={fields?.brandImage}
                  className="w-[90%] h-[90%] sm:w-[85%] sm:h-[85%] lg:w-[75%] lg:h-[75%] object-contain object-center mx-auto"
                  maxWidth={500}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Tag({ className = undefined, label }: { className?: string; label: string }) {
  return (
    <div
      className={clsx(
        'block--shadow block--gradient element--space inline-block py-2 lg:py-4 px-6 lg:px-8 uppercase font-black text-xl lg:text-2xl text-white rounded-xl lg:rounded-2xl',
        className
      )}
    >
      {label}
    </div>
  )
}
