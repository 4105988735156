import Content from '~/components/elements/Content'
import Circle from '~/components/shapes/Circle'
import LineCircle from '~/components/shapes/LineCircle'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentText({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentText" className="section">
      <div className="container">
        <div className="relative max-w-screen-md mx-auto">
          <Content className="content">{fields?.description}</Content>
          <div className="absolute max-xl:hidden top-2 -right-16">
            <LineCircle className="absolute -left-6 top-full z-10" />
            <Circle className="max-sm:hidden absolute -left-10 top-24 z-0" />
          </div>
        </div>
      </div>
    </section>
  )
}
