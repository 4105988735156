import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import Slider, { Slide, SliderNavigation } from '~/components/elements/Slider'
import { Autoplay, Navigation } from 'swiper/modules'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import FsLightbox from 'fslightbox-react'
import { useState } from 'react'

export default function ContentImages({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [toggler, setToggler] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <section data-component="ContentImages" className="section">
      <div className="container">
        <Slider
          breakpoints={{
            1024: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            420: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 20
            }
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false
          }}
          loop={true}
          modules={[Autoplay, Navigation]}
          navigation={{
            prevEl: `.slider-prev`,
            nextEl: `.slider-next`
          }}
        >
          {fields?.images?.map((image, index) => (
            <Slide key={index}>
              <button
                type="button"
                onClick={() => {
                  setCurrentSlide(index + 1)
                  setToggler(!toggler)
                }}
                className="m-0 hover:opacity-80"
              >
                <LossePlaatjie
                  className="w-full aspect-[16/10] object-cover rounded-xl sm:rounded-3xl overflow-hidden"
                  src={image}
                  maxwidth={1000}
                />
              </button>
            </Slide>
          ))}
          <SliderNavigation />
        </Slider>
      </div>
      <FsLightbox sources={fields?.images?.map((image) => image?.mediaItemUrl)} slide={currentSlide} toggler={toggler} />
    </section>
  )
}
