import clsx from 'clsx'

export default function LineCircle({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="203"
      height="202"
      viewBox="0 0 203 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <mask id="mask0_166_206" maskUnits="userSpaceOnUse" x="0" y="0" width="203" height="202">
        <path
          d="M166.37 23.573C123.606 -12.2322 59.9117 -6.58614 24.1065 36.1785C-11.6986 78.9432 -6.05264 142.637 36.712 178.442C79.4767 214.248 143.171 208.602 178.976 165.837C214.781 123.072 209.135 59.3782 166.37 23.573ZM66.9535 141.912C44.2675 122.919 41.2759 89.1334 60.2645 66.4513C79.2571 43.7653 113.043 40.7737 135.725 59.7624C158.411 78.755 161.403 112.541 142.414 135.223C123.422 157.909 89.6356 160.901 66.9535 141.912Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_166_206)">
        <path d="M-97.1025 75.0537L169.605 298.342" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-89.8293 66.3651L176.882 289.653" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-82.5523 57.6765L184.155 280.961" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-75.2791 48.984L191.432 272.272" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-68.002 40.2954L198.706 263.584" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-60.7289 31.6068L205.983 254.891" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-53.4557 22.9143L213.256 246.202" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-46.1786 14.2257L220.533 237.514" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-38.9055 5.5371L227.806 228.821" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-31.6284 -3.15541L235.083 220.133" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-24.3552 -11.844L242.356 211.444" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-17.0781 -20.5326L249.63 202.752" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-9.80493 -29.2212L256.907 194.063" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M-2.52783 -37.9137L264.18 185.375" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M4.74536 -46.6023L271.457 176.686" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M12.0225 -55.2909L278.73 167.993" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M19.2956 -63.9834L286.007 159.305" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M26.5727 -72.672L293.28 150.616" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M33.8458 -81.3606L300.557 141.924" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M41.1229 -90.0531L307.831 133.235" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}
