import clsx from 'clsx'

export default function StainTwo({ className }: { className?: string }) {
  return (
    <svg
      width="513"
      height="555"
      viewBox="0 0 513 555"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M1.8456 522.403C9.22658 546.567 34.859 560.172 58.9721 552.725C68.7476 549.76 76.7379 543.712 82.3765 536.011C115.04 491.735 368.642 403.711 435.848 425.763C446.975 429.374 459.348 429.792 471.396 426.117C502.464 416.62 519.902 383.658 510.415 352.576C503.119 328.786 482.117 313.013 458.772 311.207C439.607 309.705 413.061 299.149 384.13 282.645C383.646 281.944 383.161 281.243 382.677 280.542C387.557 273.366 392.668 269.397 398.281 267.154C403.512 265.606 408.281 264.637 412.47 264.329C413.04 264.28 413.61 264.231 414.18 264.181C418.724 263.887 423.358 263.098 427.902 261.68C458.97 252.183 476.408 219.22 466.921 188.139C457.729 158.235 426.936 141.034 396.951 148.228C375.025 153.47 350.844 154.576 326.346 150.978C314.774 98.026 337.594 82.8391 352.786 68.3546C363.171 58.4049 367.737 43.1575 363.215 28.4996C356.838 7.78625 334.921 -3.81473 314.234 2.47049C299.151 7.12281 288.895 20.0056 286.92 34.7554C283.94 56.4176 285.276 85.196 229.256 107.297C212.437 93.2049 196.958 75.6818 183.716 54.2788L183.658 54.3192C163.913 22.1338 124.297 6.03017 86.5104 17.6722C41.652 31.4451 16.4078 78.962 30.2063 123.897C31.4971 128.012 33.0797 131.925 34.9363 135.734L34.8779 135.775C50.107 167.545 55.7916 174.145 35.3898 241.279C25.6256 272.73 24.9331 307.316 35.3346 341.218C35.5228 341.865 35.8098 342.53 35.998 343.177C41.3671 360.184 45.0989 374.698 47.4176 387.168C37.6652 429.749 24.4872 464.254 11.675 478.56C1.3965 490.162 -2.8328 506.645 1.98474 522.48L1.8456 522.403Z"
        fill="#EF5241"
      />
    </svg>
  )
}
