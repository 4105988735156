import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Cross from '~/components/shapes/Cross'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBrands({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentBrands" className="section">
      <div className="container">
        <h2 className="title">{fields?.title}</h2>
        <div className="mt-6 lg:mt-10 lg:ml-20 lg:mr-32 relative">
          <div className="grid grid-cols-2 xs:grid-cols-5 lg:grid-cols-11 gap-4 lg:gap-6">
            {fields?.brands?.map((brand, index) => (
              <LosseLink
                key={index}
                to={brand?.link?.url}
                target={brand?.link?.target}
                className={clsx(
                  index % 6 === 0 || index % 5 === 0 ? 'lg:col-span-5' : 'lg:col-span-3',
                  index === 0 || index === 3 || index === 4 || index === 7
                    ? 'max-xs:col-span-1 max-lg:col-span-3'
                    : 'max-xs:col-span-1 max-lg:col-span-2',
                  'block--gradient block--shadow rounded-2xl lg:rounded-3xl p-4 sm:p-4 md:p-6 group hover:scale-105 transition-all focus:scale-95'
                )}
              >
                <h3 className="uppercase xs:text-xl lg:text-2xl font-black text-white group-hover:text-co-flamingo group-hover:underline">
                  {brand?.link?.title}
                </h3>
                <div className="flex justify-end mt-2 sm:mt-4">
                  <LossePlaatjie
                    src={brand?.image}
                    alt={brand?.link?.title}
                    className="h-[75px] sm:h-[100px] object-contain object-right"
                    width={500}
                  />
                </div>
              </LosseLink>
            ))}
          </div>
          <Cross className="max-md:hidden absolute -bottom-24 -right-24" />
        </div>
      </div>
    </section>
  )
}
