import clsx from 'clsx'

export default function DotBlock({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="68"
      height="268"
      viewBox="0 0 68 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M1.16997 263.914C3.23099 261.666 6.78529 265.226 4.53821 267.282C2.47719 269.529 -1.07712 265.97 1.16997 263.914Z"
        fill="white"
      />
      <path
        d="M1.16997 250.059C3.23099 247.812 6.78529 251.371 4.53821 253.427C2.47719 255.674 -1.07712 252.115 1.16997 250.059Z"
        fill="white"
      />
      <path
        d="M1.16997 236.209C3.23099 233.962 6.78529 237.521 4.53821 239.577C2.47719 241.824 -1.07712 238.265 1.16997 236.209Z"
        fill="white"
      />
      <path
        d="M1.16997 222.354C3.23099 220.107 6.78529 223.666 4.53821 225.723C2.47719 227.97 -1.07712 224.411 1.16997 222.354Z"
        fill="white"
      />
      <path
        d="M1.16997 208.504C3.23099 206.257 6.78529 209.816 4.53821 211.873C2.47719 214.12 -1.07712 210.561 1.16997 208.504Z"
        fill="white"
      />
      <path
        d="M1.16997 194.65C3.23099 192.403 6.78529 195.962 4.53821 198.018C2.47719 200.265 -1.07712 196.706 1.16997 194.65Z"
        fill="white"
      />
      <path
        d="M1.16997 180.795C3.23099 178.548 6.78529 182.107 4.53821 184.163C2.47719 186.411 -1.07712 182.851 1.16997 180.795Z"
        fill="white"
      />
      <path
        d="M1.16997 166.945C3.23099 164.698 6.78529 168.257 4.53821 170.314C2.47719 172.561 -1.07712 169.002 1.16997 166.945Z"
        fill="white"
      />
      <path
        d="M1.16997 153.091C3.23099 150.844 6.78529 154.403 4.53821 156.459C2.47719 158.706 -1.07712 155.147 1.16997 153.091Z"
        fill="white"
      />
      <path
        d="M1.16997 139.241C3.23099 136.994 6.78529 140.553 4.53821 142.609C2.47719 144.856 -1.07712 141.297 1.16997 139.241Z"
        fill="white"
      />
      <path
        d="M1.16997 125.386C3.23099 123.139 6.78529 126.698 4.53821 128.754C2.47719 131.002 -1.07712 127.442 1.16997 125.386Z"
        fill="white"
      />
      <path
        d="M1.16997 111.536C3.23099 109.289 6.78529 112.848 4.53821 114.905C2.47719 117.152 -1.07712 113.593 1.16997 111.536Z"
        fill="white"
      />
      <path
        d="M1.16997 97.6816C3.23099 95.4346 6.78529 98.9936 4.53821 101.05C2.47719 103.297 -1.07712 99.7379 1.16997 97.6816Z"
        fill="white"
      />
      <path
        d="M1.16997 83.8318C3.23099 81.5847 6.78529 85.1438 4.53821 87.2C2.47719 89.4471 -1.07712 85.8881 1.16997 83.8318Z"
        fill="white"
      />
      <path
        d="M1.16997 69.9771C3.23099 67.7301 6.78529 71.2891 4.53821 73.3454C2.47719 75.5925 -1.07712 72.0334 1.16997 69.9771Z"
        fill="white"
      />
      <path
        d="M1.16997 56.1273C3.23099 53.8802 6.78529 57.4393 4.53821 59.4955C2.47719 61.7426 -1.07712 58.1835 1.16997 56.1273Z"
        fill="white"
      />
      <path
        d="M1.16997 42.2727C3.23099 40.0256 6.78529 43.5847 4.53821 45.6409C2.47719 47.888 -1.07712 44.3289 1.16997 42.2727Z"
        fill="white"
      />
      <path
        d="M1.16997 28.4228C3.23099 26.1757 6.78529 29.7348 4.53821 31.791C2.47719 34.0381 -1.07712 30.479 1.16997 28.4228Z"
        fill="white"
      />
      <path
        d="M1.16997 14.5681C3.23099 12.321 6.78529 15.8801 4.53821 17.9364C2.47719 20.1834 -1.07712 16.6244 1.16997 14.5681Z"
        fill="white"
      />
      <path
        d="M1.16991 0.718229C3.23093 -1.52886 6.78523 2.03022 4.53815 4.08647C2.4819 6.33356 -1.07241 2.77448 1.16991 0.718229Z"
        fill="white"
      />
      <path
        d="M32.3716 263.914C34.4326 261.666 37.987 265.226 35.7399 267.282C33.6788 269.529 30.1245 265.97 32.3716 263.914Z"
        fill="white"
      />
      <path
        d="M32.3716 250.059C34.4326 247.812 37.987 251.371 35.7399 253.427C33.6788 255.674 30.1245 252.115 32.3716 250.059Z"
        fill="white"
      />
      <path
        d="M32.3716 236.209C34.4326 233.962 37.987 237.521 35.7399 239.577C33.6788 241.824 30.1245 238.265 32.3716 236.209Z"
        fill="white"
      />
      <path
        d="M32.3716 222.354C34.4326 220.107 37.987 223.666 35.7399 225.723C33.6788 227.97 30.1245 224.411 32.3716 222.354Z"
        fill="white"
      />
      <path
        d="M32.3716 208.504C34.4326 206.257 37.987 209.816 35.7399 211.873C33.6788 214.12 30.1245 210.561 32.3716 208.504Z"
        fill="white"
      />
      <path
        d="M32.3716 194.65C34.4326 192.403 37.987 195.962 35.7399 198.018C33.6788 200.265 30.1245 196.706 32.3716 194.65Z"
        fill="white"
      />
      <path
        d="M32.3716 180.795C34.4326 178.548 37.987 182.107 35.7399 184.163C33.6788 186.411 30.1245 182.851 32.3716 180.795Z"
        fill="white"
      />
      <path
        d="M32.3716 166.945C34.4326 164.698 37.987 168.257 35.7399 170.314C33.6788 172.561 30.1245 169.002 32.3716 166.945Z"
        fill="white"
      />
      <path
        d="M32.3716 153.091C34.4326 150.844 37.987 154.403 35.7399 156.459C33.6788 158.706 30.1245 155.147 32.3716 153.091Z"
        fill="white"
      />
      <path
        d="M32.3716 139.241C34.4326 136.994 37.987 140.553 35.7399 142.609C33.6788 144.856 30.1245 141.297 32.3716 139.241Z"
        fill="white"
      />
      <path
        d="M32.3716 125.386C34.4326 123.139 37.987 126.698 35.7399 128.754C33.6788 131.002 30.1245 127.442 32.3716 125.386Z"
        fill="white"
      />
      <path
        d="M32.3716 111.536C34.4326 109.289 37.987 112.848 35.7399 114.905C33.6788 117.152 30.1245 113.593 32.3716 111.536Z"
        fill="white"
      />
      <path
        d="M32.3716 97.6816C34.4326 95.4346 37.987 98.9936 35.7399 101.05C33.6788 103.297 30.1245 99.7379 32.3716 97.6816Z"
        fill="white"
      />
      <path
        d="M32.3716 83.8318C34.4326 81.5847 37.987 85.1438 35.7399 87.2C33.6788 89.4471 30.1245 85.8881 32.3716 83.8318Z"
        fill="white"
      />
      <path
        d="M32.3716 69.9771C34.4326 67.7301 37.987 71.2891 35.7399 73.3454C33.6788 75.5925 30.1245 72.0334 32.3716 69.9771Z"
        fill="white"
      />
      <path
        d="M32.3716 56.1273C34.4326 53.8802 37.987 57.4393 35.7399 59.4955C33.6788 61.7426 30.1245 58.1835 32.3716 56.1273Z"
        fill="white"
      />
      <path
        d="M32.3716 42.2727C34.4326 40.0256 37.987 43.5847 35.7399 45.6409C33.6788 47.888 30.1245 44.3289 32.3716 42.2727Z"
        fill="white"
      />
      <path
        d="M32.3716 28.4228C34.4326 26.1757 37.987 29.7348 35.7399 31.791C33.6788 34.0381 30.1245 30.479 32.3716 28.4228Z"
        fill="white"
      />
      <path
        d="M32.3716 14.5681C34.4326 12.321 37.987 15.8801 35.7399 17.9364C33.6788 20.1834 30.1245 16.6244 32.3716 14.5681Z"
        fill="white"
      />
      <path
        d="M32.3716 0.718229C34.4326 -1.52886 37.987 2.03022 35.7399 4.08647C33.6788 6.33356 30.1245 2.77448 32.3716 0.718229Z"
        fill="white"
      />
      <path
        d="M63.5733 263.914C65.6343 261.666 69.1886 265.226 66.9415 267.282C64.8805 269.529 61.3262 265.97 63.5733 263.914Z"
        fill="white"
      />
      <path
        d="M63.5733 250.059C65.6343 247.812 69.1886 251.371 66.9415 253.427C64.8805 255.674 61.3262 252.115 63.5733 250.059Z"
        fill="white"
      />
      <path
        d="M63.5733 236.209C65.6343 233.962 69.1886 237.521 66.9415 239.577C64.8805 241.824 61.3262 238.265 63.5733 236.209Z"
        fill="white"
      />
      <path
        d="M63.5733 222.354C65.6343 220.107 69.1886 223.666 66.9415 225.723C64.8805 227.97 61.3262 224.411 63.5733 222.354Z"
        fill="white"
      />
      <path
        d="M63.5733 208.504C65.6343 206.257 69.1886 209.816 66.9415 211.873C64.8805 214.12 61.3262 210.561 63.5733 208.504Z"
        fill="white"
      />
      <path
        d="M63.5733 194.65C65.6343 192.403 69.1886 195.962 66.9415 198.018C64.8805 200.265 61.3262 196.706 63.5733 194.65Z"
        fill="white"
      />
      <path
        d="M63.5733 180.795C65.6343 178.548 69.1886 182.107 66.9415 184.163C64.8805 186.411 61.3262 182.851 63.5733 180.795Z"
        fill="white"
      />
      <path
        d="M63.5733 166.945C65.6343 164.698 69.1886 168.257 66.9415 170.314C64.8805 172.561 61.3262 169.002 63.5733 166.945Z"
        fill="white"
      />
      <path
        d="M63.5733 153.091C65.6343 150.844 69.1886 154.403 66.9415 156.459C64.8805 158.706 61.3262 155.147 63.5733 153.091Z"
        fill="white"
      />
      <path
        d="M63.5733 139.241C65.6343 136.994 69.1886 140.553 66.9415 142.609C64.8805 144.856 61.3262 141.297 63.5733 139.241Z"
        fill="white"
      />
      <path
        d="M63.5733 125.386C65.6343 123.139 69.1886 126.698 66.9415 128.754C64.8805 131.002 61.3262 127.442 63.5733 125.386Z"
        fill="white"
      />
      <path
        d="M63.5733 111.536C65.6343 109.289 69.1886 112.848 66.9415 114.905C64.8805 117.152 61.3262 113.593 63.5733 111.536Z"
        fill="white"
      />
      <path
        d="M63.5733 97.6816C65.6343 95.4346 69.1886 98.9936 66.9415 101.05C64.8805 103.297 61.3262 99.7379 63.5733 97.6816Z"
        fill="white"
      />
      <path
        d="M63.5733 83.8318C65.6343 81.5847 69.1886 85.1438 66.9415 87.2C64.8805 89.4471 61.3262 85.8881 63.5733 83.8318Z"
        fill="white"
      />
      <path
        d="M63.5733 69.9771C65.6343 67.7301 69.1886 71.2891 66.9415 73.3454C64.8805 75.5925 61.3262 72.0334 63.5733 69.9771Z"
        fill="white"
      />
      <path
        d="M63.5733 56.1273C65.6343 53.8802 69.1886 57.4393 66.9415 59.4955C64.8805 61.7426 61.3262 58.1835 63.5733 56.1273Z"
        fill="white"
      />
      <path
        d="M63.5733 42.2727C65.6343 40.0256 69.1886 43.5847 66.9415 45.6409C64.8805 47.888 61.3262 44.3289 63.5733 42.2727Z"
        fill="white"
      />
      <path
        d="M63.5733 28.4228C65.6343 26.1757 69.1886 29.7348 66.9415 31.791C64.8805 34.0381 61.3262 30.479 63.5733 28.4228Z"
        fill="white"
      />
      <path
        d="M63.5733 14.5681C65.6343 12.321 69.1886 15.8801 66.9415 17.9364C64.8805 20.1834 61.3262 16.6244 63.5733 14.5681Z"
        fill="white"
      />
      <path
        d="M63.5733 0.718229C65.6343 -1.52886 69.1886 2.03022 66.9415 4.08647C64.8805 6.33356 61.3262 2.77448 63.5733 0.718229Z"
        fill="white"
      />
    </svg>
  )
}
