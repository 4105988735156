import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerBrand from './BannerBrand'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    brand: BannerBrand
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
