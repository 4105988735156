/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import LineCircle from '~/components/shapes/LineCircle'
import Circle from '~/components/shapes/Circle'
import { AnimatePresence, motion } from 'framer-motion'

export default function HeaderMobile() {
  const { setScrollable } = useLosseLayout()
  const location = useLocation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        onClick={() => {
          setMobileMenuOpen(!mobileMenuOpen)
          setScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        title="Open menu"
      >
        <div className="mx-auto my-[5px] h-[2px] w-full bg-co-fuscous-gray" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-co-fuscous-gray" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-co-fuscous-gray" />
      </button>
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-100%' }}
            className="fixed left-0 top-0 z-50 flex h-screen-dynamic w-screen items-center justify-center bg-co-alto"
          >
            <div
              className="absolute right-5 top-5 text-center"
              role="button"
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen)
                setScrollable(true)
              }}
              aira-label="Close menu"
            >
              <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-co-fuscous-gray before:[content:''] after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-co-fuscous-gray after:[content:'']" />
            </div>
            <ul className="m-0 w-8/12 pl-0 relative z-20 flex flex-col gap-8">
              <HeaderMenu />
            </ul>
            <div className="opacity-75">
              <LineCircle className="absolute left-10 top-10 z-10" />
              <Circle className="absolute left-10 top-44 z-0 !bg-co-flamingo" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
