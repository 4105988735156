import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentImage from './ContentImage'
import ContentImages from './ContentImages'
import ContentTitle from './ContentTitle'
import ContentBrands from './ContentBrands'
import ContentLogoSlider from './ContentLogoSlider'
import ContentText from './ContentText'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    brands: ContentBrands,
    image: ContentImage,
    images: ContentImages,
    title: ContentTitle,
    logo_slider: ContentLogoSlider,
    text: ContentText
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
