import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Banner, Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function Links({ fields }: { fields: Page_Flexcontent_Flex_Banner | Page_Flexcontent_Flex_Content }) {
  return fields.links ? (
    <div className="flex items-center gap-3 sm:gap-4 lg:gap-6">
      {fields.links.map((link, index) => (
        <LosseLink key={index} className={clsx(index === 0 ? 'btn--flamingo' : 'btn--fuscous-gray')} src={link?.link} />
      ))}
    </div>
  ) : null
}
