import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import Circle from '~/components/shapes/Circle'
import LineCircle from '~/components/shapes/LineCircle'
import StainTwo from '~/components/shapes/StainTwo'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" className="section">
      <div className="container">
        <div className="grid gap-6 xl:gap-10 sm:grid-cols-2">
          <div>
            <div className="relative max-sm:w-[60%] max-sm:mx-auto">
              <LossePlaatjie
                maxWidth={1000}
                src={fields?.image}
                className="w-full aspect-square object-cover rounded-full overflow-hidden relative z-10"
              />
              <StainTwo className="absolute w-[90%] h-[90%] -left-[45px] lg:-left-[65px] -top-4 z-0" />
              <LineCircle className="absolute top-12 sm:top-32 -left-20 z-20 max-sm:w-[100px] max-sm:h-[100px] max-lg:w-[150px] max-lg:h-[150px]" />
              <Circle className="absolute right-8 bottom-0 sm:right-32 sm:-bottom-6 z-20 max-sm:w-[50px] max-sm:h-[50px] max-lg:w-[75px] max-lg:h-[75px]" />
            </div>
          </div>
          <div>
            <h2 className="title sm:-mx-24 element--space sm:w-[calc(100%+theme(space.24))]">{fields?.title}</h2>
            <div className="md:pl-10 md:pr-10 xl:pr-20">
              <Content className="content lg:text-lg element--space">{fields?.description}</Content>
              <Links fields={fields} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
