import { useLoaderData, useLosseLanguage, useMatches } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import FormDuuf from '@ubo/form-duuf'
import Loading from './Loading'

const SUBJECT: { [key: number]: string } = {
  1: 'input_12'
}

export default function Form(props: FormDuufProps) {
  const matches = useMatches()
  const data = useLoaderData()
  const [, locale] = useLosseLanguage()
  const formId = props.data.formId
  const apiUrl = `${matches[0].data.config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`

  let defaultFields = undefined

  if (SUBJECT[formId]) {
    defaultFields = {
      [SUBJECT[formId]]: data?.page?.title
    }
  }

  return (
    <FormDuuf
      apiUrl={apiUrl}
      {...props}
      defaultFields={defaultFields}
      renderLoading={() => (
        <div className="absolute block--gradient left-0 top-0 w-full h-full z-10 text-co-flamingo flex--center">
          <Loading />
        </div>
      )}
      scrollToConfirm={false}
      privacyUrl={locale === 'nl_NL' ? '/privacyverklaring/' : '/en/privacy-statement/'}
      privacyUrlPlacement={locale === 'nl_NL' ? 'privacyverklaring' : 'privacy statement'}
    />
  )
}
