import { LosseLink, useLoaderData, useLosseLanguage } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [translations, currentLocale] = useLosseLanguage()
  const translation = translations.find((_translation) => _translation.language.default_locale !== currentLocale)

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        return (
          <li key={index}>
            <LosseLink
              className="uppercase font-bold text-4xl lg:text-xl text-co-fuscous-gray hover:text-co-flamingo hover:underline flex--center"
              to={edge?.link?.url || '/'}
            >
              <div className="active-dot hidden w-3 h-3 mr-4 lg:w-2 lg:h-2 rounded-full bg-co-flamingo lg:mr-2" />
              {edge?.link?.title}
            </LosseLink>
          </li>
        )
      })}
      {translation && (
        <li className="flex justify-center">
          <LosseLink to={translation.href.replace('home/', '')} className="flex items-center text-sm xl:text-base">
            <span
              className={clsx(
                currentLocale === 'nl_NL' ? 'font-semibold' : 'group-hover:underline opacity-50 group-hover:opacity-100',
                'mr-2 lg:mr-1 max-lg:text-lg'
              )}
            >
              NL
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div
                className={clsx(
                  'w-12 lg:w-11 h-7 lg:h-6 peer-focus:outline-none peer-focus:ring-2 rounded-full peer bg-co-wild-sand lg:bg-co-alto peer-checked:after:translate-x-full peer-checked:after:border-co-bg-co-flamingo after:absolute after:top-[2px] after:bg-co-flamingo after:border-co-bg-co-flamingo after:border after:rounded-full after:h-6 after:w-6 lg:after:h-5 lg:after:w-5 after:transition-all border-gray-600',
                  currentLocale === 'nl_NL' ? 'after:left-[2px]' : 'after:right-[2px]'
                )}
              ></div>
            </label>
            <span
              className={clsx(
                currentLocale === 'nl_NL' ? 'group-hover:underline opacity-50 group-hover:opacity-100' : 'font-semibold',
                'ml-2 lg:ml-1 max-lg:text-lg'
              )}
            >
              EN
            </span>
          </LosseLink>
        </li>
      )}
    </>
  )
}
