import clsx from 'clsx'
import useInfo from '~/utils/useInfo'

export default function ContactInfo({ textClassName = undefined }: { textClassName?: string }) {
  const info = useInfo()

  return (
    <>
      <div className="flex items-start">
        <div className="w-8">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative top-[2px]">
            <g id="fi-ss-marker" clipPath="url(#clip0_166_613)">
              <path
                id="Vector"
                d="M9 0C7.01155 0.0021837 5.10516 0.793061 3.69911 2.19911C2.29306 3.60516 1.50218 5.51155 1.5 7.5C1.5 11.4615 7.8 16.8997 8.5155 17.5072L9 17.9167L9.4845 17.5072C10.2 16.8997 16.5 11.4615 16.5 7.5C16.4978 5.51155 15.7069 3.60516 14.3009 2.19911C12.8948 0.793061 10.9885 0.0021837 9 0V0ZM9 11.25C8.25832 11.25 7.5333 11.0301 6.91661 10.618C6.29993 10.206 5.81928 9.62029 5.53545 8.93506C5.25162 8.24984 5.17736 7.49584 5.32205 6.76841C5.46675 6.04098 5.8239 5.3728 6.34835 4.84835C6.8728 4.3239 7.54098 3.96675 8.26841 3.82206C8.99584 3.67736 9.74984 3.75162 10.4351 4.03545C11.1203 4.31928 11.706 4.79993 12.118 5.41661C12.5301 6.0333 12.75 6.75832 12.75 7.5C12.7488 8.4942 12.3533 9.44733 11.6503 10.1503C10.9473 10.8533 9.9942 11.2488 9 11.25Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
        <div>
          {info?.addressRuleOne}
          <br />
          {info?.addressRuleTwo}
        </div>
      </div>
      <div className="flex items-start mt-4">
        <div className="w-8">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative top-[2px]">
            <g id="fi-ss-envelope" clipPath="url(#clip0_166_609)">
              <path
                id="Vector"
                d="M7.82029 9.93258C8.26645 10.3769 8.8705 10.6264 9.50021 10.6264C10.1299 10.6264 10.734 10.3769 11.1801 9.93258L18.8268 2.28508C18.6508 1.84489 18.347 1.46746 17.9547 1.20137C17.5623 0.93528 17.0993 0.792703 16.6252 0.791992H2.37521C1.90113 0.792703 1.43811 0.93528 1.04574 1.20137C0.653377 1.46746 0.349626 1.84489 0.173584 2.28508L7.82029 9.93258Z"
                fill="currentColor"
              />
              <path
                id="Vector_2"
                d="M12.2993 11.052C11.5563 11.7932 10.5495 12.2094 9.5 12.2094C8.45045 12.2094 7.44375 11.7932 6.70067 11.052L0 4.35449V18.2087H19V4.35449L12.2993 11.052Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
        <div>
          <a href={`mailto:${info?.email}`} className={clsx('hover:underline', textClassName)}>
            {info?.email}
          </a>
        </div>
      </div>
      <div className="flex items-start mt-4">
        <div className="w-8">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative top-[2px]">
            <g id="fi-ss-phone-call" clipPath="url(#clip0_166_616)">
              <path
                id="Vector"
                d="M13.2097 9.636L17.9084 14.3348L15.5302 16.713C15.1157 17.1258 14.6233 17.4521 14.0816 17.673C13.54 17.894 12.9599 18.0051 12.3749 18C6.9389 18 -9.78147e-05 11.061 -9.78147e-05 5.625C-0.0048553 5.03999 0.106441 4.45984 0.327352 3.91812C0.548263 3.3764 0.874403 2.88386 1.2869 2.469L3.66515 0.0915L8.3639 4.79025L5.27015 7.884C6.18989 10.0697 7.92633 11.8102 10.1099 12.735L13.2097 9.636ZM16.4999 7.5H17.9999C17.9977 5.51155 17.2068 3.60516 15.8008 2.19911C14.3947 0.793061 12.4884 0.0021837 10.4999 0V1.5C12.0907 1.50179 13.6157 2.1345 14.7406 3.25933C15.8654 4.38416 16.4981 5.90925 16.4999 7.5ZM13.4999 7.5H14.9999C14.9987 6.30689 14.5242 5.16299 13.6806 4.31934C12.8369 3.47568 11.693 3.00119 10.4999 3V4.5C11.2956 4.5 12.0586 4.81607 12.6212 5.37868C13.1838 5.94129 13.4999 6.70435 13.4999 7.5Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
        <div>
          <a href={`tel:${info?.phone}`} className={clsx('hover:underline', textClassName)}>
            {info?.phone}
          </a>
        </div>
      </div>
    </>
  )
}
