/* eslint-disable react-hooks/exhaustive-deps */
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import Slider, { Slide, SliderNavigation } from '~/components/elements/Slider'
import { Autoplay, Navigation } from 'swiper/modules'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { useEffect, useRef, useState } from 'react'
import type Swiper from 'swiper'

export default function ContentLogoSlider({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [swiper, setSwiper] = useState<Swiper | null>(null)
  const targetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!swiper || !targetRef?.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            swiper?.autoplay?.start()
          } else {
            swiper?.autoplay?.stop()
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      }
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (!targetRef.current) return

      observer.unobserve(targetRef.current)
    }
  }, [swiper, targetRef])

  return (
    <section ref={targetRef} data-component="ContentLogoSlider" className="section">
      <div className="container">
        <h2 className="element--space title--base text-white text-4xl">{fields?.title}</h2>
        <Slider
          onSwiper={(swiper) => {
            setSwiper(swiper)
          }}
          breakpoints={{
            1024: {
              slidesPerView: 6,
              spaceBetween: 50
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 20
            },
            420: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            0: {
              slidesPerView: 2,
              spaceBetween: 20
            }
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false
          }}
          loop={true}
          modules={[Autoplay, Navigation]}
          navigation={{
            prevEl: `.slider-prev`,
            nextEl: `.slider-next`
          }}
          className="!py-3 !px-4 !-mx-4"
        >
          {fields?.logos?.map((logo, index) => (
            <Slide key={index} className="!h-[100px]">
              <LosseLink
                to={logo?.link?.url}
                target={logo?.link?.url}
                className="text-[0] h-full w-full group flex--center hover:scale-105 transition-all"
              >
                <LossePlaatjie
                  className="max-w-full max-h-full object-contain grayscale object-center group-hover:grayscale-0 transition-all"
                  src={logo?.image}
                  maxwidth={250}
                  autoheight={false}
                />
                {logo?.link?.title}
              </LosseLink>
            </Slide>
          ))}
          <SliderNavigation />
        </Slider>
      </div>
    </section>
  )
}
