import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Form from '~/components/elements/Form'
import { createElement, useState } from 'react'
import ContactInfo from '~/components/elements/ContactInfo'
import { useLosseLanguage } from '@ubo/losse-sjedel'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [isSend, setSend] = useState(false)
  const [, locale] = useLosseLanguage()

  return (
    <section data-component="FormContact" className="section" id="contact">
      <div className="container lg:pr-20 lg:pl-10">
        <div className="grid md:grid-cols-2 lg:grid-cols-7 gap-10 lg:gap-6 xl:gap-10">
          <div className="lg:col-span-4">
            {createElement(fields?.titleAs || 'h1', { className: 'title element--space' }, fields?.title)}
            <div className="lg:px-20">
              <Content className="content element--space">{fields?.description}</Content>
              <div className="lg:text-lg">
                <ContactInfo textClassName="text-black hover:text-co-flamingo" />
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 block--shadow block--gradient rounded-3xl p-4 sm:p-6 xl:p-10 relative overflow-hidden h-fit">
            {!isSend && <h2 className="title--alt element--space">{locale === 'nl_NL' ? 'Stuur een bericht' : 'Send a message'}</h2>}
            <Form
              data={fields.form}
              onSubmitDone={() => {
                setSend(true)
              }}
              renderStatus={({ content }) => (
                <>
                  <div className="flex--center element--space">
                    <div className="bg-co-fuscous-gray flex--center w-24 h-24 rounded-full box--shadow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-white w-[60%] h-[60%]"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                  </div>
                  <Content className="content">{content}</Content>
                </>
              )}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
