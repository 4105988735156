import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

export const Slide = SwiperSlide

export default function Slider(props: SwiperProps) {
  return (
    <>
      <Swiper {...props}>{props.children}</Swiper>
    </>
  )
}

export function SliderNavigation() {
  return (
    <div className="mt-8 lg:mt-10 flex justify-center gap-4 pb-2">
      <button className="slider-prev text-white bg-co-flamingo w-8 h-8 lg:w-10 lg:h-10 rounded-full flex--center btn--pseudo text-[0px]">
        <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              id="Vector"
              d="M26.2041 33.7625L14.6669 22.7497C14.3232 22.4215 14.1301 21.9765 14.1301 21.5125C14.1301 21.0484 14.3232 20.6034 14.6669 20.2752L26.2041 9.26245V33.7625Z"
              fill="currentColor"
            />
          </g>
        </svg>
        Vorige
      </button>
      <button className="slider-next text-white bg-co-flamingo w-8 h-8 lg:w-10 lg:h-10 rounded-full flex--center btn--pseudo text-[0px]">
        <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              id="Vector"
              d="M17.3918 33.7625V9.26245L28.6668 20.2752C29.0027 20.6034 29.1914 21.0484 29.1914 21.5125C29.1914 21.9765 29.0027 22.4215 28.6668 22.7497L17.3918 33.7625Z"
              fill="currentColor"
            />
          </g>
        </svg>
        Volgende
      </button>
    </div>
  )
}
