import { useLoaderData, useLosseLanguage } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import LineCircle from '../shapes/LineCircle'
import ContactInfo from '../elements/ContactInfo'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()
  const [, locale] = useLosseLanguage()

  return (
    <footer data-component="Footer" className="bg-co-fuscous-gray relative overflow-hidden mt-12 lg:mt-20">
      <div className="container py-10 text-white">
        <div className="grid grid-cols-2 max-sm:gap-10 sm:grid-cols-4 md:pr-32 lg:pr-64">
          <div className="col-span-2">
            <h2 className="uppercase text-base font-bold">Contact</h2>

            <div className="mt-5">
              <ContactInfo textClassName="text-white hover:text-co-flamingo" />
            </div>
          </div>

          <div>
            <h2 className="uppercase text-base font-bold">Menu</h2>
            <ul className="mt-5">
              {footer?.menuFooter?.map((edge, index) => (
                <li className="py-1" key={index}>
                  <LosseLink className="hover:underline text-white hover:text-co-flamingo" to={edge?.link?.url || '/'}>
                    {edge?.link?.title}
                  </LosseLink>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="uppercase text-base font-bold">{locale === 'nl_NL' ? 'Merken' : 'Brands'}</h2>
            <ul className="mt-5">
              {footer?.brandsFooter?.map((edge, index) => (
                <li className="py-1" key={index}>
                  <LosseLink className="hover:underline text-white hover:text-co-flamingo" to={edge?.link?.url || '/'}>
                    {edge?.link?.title}
                  </LosseLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container pb-8 mt-4 sm:mt-10 relative text-sm flex-wrap">
        <span className="text-white/50">&copy; {new Date().getFullYear()}</span>
        {footer?.policies?.map((edge, index) => (
          <span key={index}>
            <span className="px-2 text-white/50">-</span>
            <LosseLink className="hover:underline text-white/50 hover:text-co-flamingo" src={edge?.link} />
          </span>
        ))}
        <span className="px-2 text-white/50">-</span>
        <button
          type="button"
          onClick={() => {
            // @ts-ignore
            if (typeof Cookiebot !== 'undefined') Cookiebot.show()
          }}
          className="hover:underline text-white/50 hover:text-co-flamingo"
        >
          Cookies
        </button>
      </div>
      <div className="absolute bottom-0 top-0 left-0 right-0 pointer-events-none max-xs:opacity-50">
        <div className="container relative h-full">
          <LineCircle className="absolute max-sm:opacity-50 max-sm:-top-10 sm:-bottom-10 right-0" />
        </div>
      </div>
    </footer>
  )
}
