import Content from '~/components/elements/Content'
import Circle from '~/components/shapes/Circle'
import LineCircle from '~/components/shapes/LineCircle'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTitle({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentTitle" className="section">
      <div className="container lg:pl-32 lg:pr-10">
        <div className="grid sm:grid-cols-5 lg:grid-cols-2 gap-10 xl:gap-12">
          <div className="relative sm:col-span-2 lg:col-span-1">
            <h2 className="title--sub relative z-20">{fields?.title}</h2>
            <div className="absolute sm:top-1/4 -translate-y-1/2 sm:-left-1/4 max-sm:opacity-50 max-sm:-left-5 max-sm:-top-5">
              <LineCircle className="absolute -left-6 top-full z-10" />
              <Circle className="max-sm:hidden absolute -left-10 top-24 z-0" />
            </div>
          </div>
          <div className="sm:col-span-3 lg:col-span-1">
            <Content className="content">{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
