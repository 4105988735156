/* eslint-disable react-hooks/exhaustive-deps */
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Links from '~/components/elements/Links'
import StainOne from '~/components/shapes/StainOne'
import LineCircle from '~/components/shapes/LineCircle'
import CircleWhite from '~/components/shapes/CircleWhite'
import Slider, { Slide } from '~/components/elements/Slider'
import { Autoplay, EffectFade } from 'swiper/modules'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault">
      <div className="container section">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:pl-20">
          <div className="max-sm:order-3 order-1 max-sm:mt-10">
            <h1 className="title element--space">{fields?.title}</h1>
            <div className="sm:pr-10 lg:pr-32 xl:pr-44">
              <Content className="content lg:text-lg max-sm:pr-0 max-lg:pr-10 element--space">{fields?.description}</Content>
              <Links fields={fields} />
            </div>
          </div>
          <div className="order-2">
            <div className="relative sm:-left-6 lg:-left-20 max-sm:w-[65%] max-sm:mx-auto">
              <Slider
                slidesPerView={1}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false
                }}
                loop={true}
                modules={[Autoplay, EffectFade]}
                effect="fade"
              >
                {fields?.images?.map((image, index) => (
                  <Slide key={index}>
                    <LossePlaatjie
                      loading="eager"
                      className="w-full aspect-square object-cover rounded-full overflow-hidden relative z-10"
                      src={image}
                      maxwidth={1000}
                    />
                  </Slide>
                ))}
              </Slider>
              <StainOne className="absolute w-[115%] h-[115%] -right-[65px] -top-12 z-0" />
              <LineCircle className="absolute -bottom-5 -right-5 z-20 max-sm:w-[100px] max-sm:h-[100px] max-lg:w-[150px] max-lg:h-[150px]" />
              <CircleWhite className="absolute left-5 bottom-5 z-20 max-sm:w-[50px] max-sm:h-[50px] max-lg:w-[75px] max-lg:h-[75px]" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
